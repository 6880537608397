import 'bootstrap/dist/css/bootstrap.css';
import '@aws-amplify/ui-react/styles.css';
import Card from '@mui/material/Card';
import seedrandom from 'seedrandom';
import {Typography, CardContent, CardActions, Button } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import affirmationsData from '../data/affirmations.json';

interface Affirmation {
    id: number;
    category: string;
    affirmation: string;
}



function CustomCard() {
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const [randomAffirmation, setRandomAffirmation] = useState<Affirmation | null>(null);
    useEffect(() => {
        const loadRandomCard = async () => {
            // Generate random seed based on today's date and loggedInUser
            const today = new Date().toDateString();
            const seed = `${today}*${user.attributes?.email}`;
            const rng = seedrandom(seed);

            // Calculate random index
            const randomIndex = Math.floor(rng() * affirmationsData.length);

            // Find the card with the random index
            const selectedCard = affirmationsData[randomIndex];

            setRandomAffirmation(selectedCard);
            };

        loadRandomCard();
        }, [user.attributes?.email]);

  return (
      <>
      <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Welcome {user.attributes?.given_name}
        </Typography>
        <Typography variant="h5" component="div">
          Your affirmation of the day
        </Typography>
        <Typography variant="body2">
            {randomAffirmation?.affirmation}
          <br />
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={signOut}>Sign out</Button>
      </CardActions>
    </Card>
      </>
  );
}

export default CustomCard;
