import 'bootstrap/dist/css/bootstrap.css';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Button from '@mui/material/Button';

import awsExports from './cognito';
import RandomAffirmation from './components/RandomAffirmation';
import { Container } from '@mui/material';
import CustomCard from './components/CustomCard';
// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
      signupAttributes: awsExports.REQUIRED_ATTRIBUITES
  }
})

function App() {

  return (
    <Authenticator signUpAttributes={
      ['family_name',
        'given_name',
      ]}>
      {({ signOut, user }) => (
      <>
      <Container maxWidth='sm'>
        <CustomCard></CustomCard>
      </Container>
      </>
    )}
    </Authenticator>
  );
}

export default App;
